import React, {useState, forwardRef, useImperativeHandle} from "react"

import {makeStyles, Theme} from "@material-ui/core/styles"
import Select from "react-select"
import type {OptionTypeBase, ActionMeta} from "react-select"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Button from "@material-ui/core/Button"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import Tooltip from "@material-ui/core/Tooltip"

import {prefectures} from "../Const"

const useStyles = makeStyles((theme: Theme) => ({
  inputs:{
    height:"96vh",
    overflowY:"scroll",
    padding:"10px 0 70px 0"
  },
  paper:{
    padding:"10px",
    margin:"10px"
  },
  text:{
    width:"95%",
    borderRadius:"4px",
    minHeight:"38px",
    border:"solid 1px hsl(0, 0%, 80%)",
    padding:"8px",
    margin:"5px"
  },
  number:{
    width:"10vh",
    borderRadius:"4px",
    minHeight:"38px",
    border:"solid 1px hsl(0, 0%, 80%)",
    padding:"8px",
    margin:"5px"
  },
  buttons:{
    position:"fixed",
    height:"50px",
    bottom:"0",
    width:"25%",
  },
  button:{
    backgroundColor:"#3f51b5",
    color:"white",
    height:"100%",
    width:"100%",
    borderRadius:"0"
  },
  tooltip:{
    fontSize:"1.25rem"
  }
}))

type FormData = {
  offset?:number,
  searchKeyword:string,
  useSimilar:string,
  useColumns:string[],
  personMin:string,
  personMax:string,
  holidayMin:string,
  holidayMax:string,
  overtimeMin:string,
  overtimeMax:string,
  incomeMin:string,
  incomeMax:string,
  incomeMonthMin:string,
  incomeMonthMax:string,
  prefecture:OptionTypeBase[],
  nearestStation:string[]
}

type Props = {
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>, buttonType: string) => void
}

const SidebarBase: React.ForwardRefRenderFunction<
  {makeFormData:() => FormData},
  Props
> = (props, ref) => {
  const classes = useStyles()

  const {
    onSubmit
  } = props

  const [searchKeyword, setSearchKeyword] = useState("")
  const [useSimilar, setUseSimilar] = useState("false")
  const [useColumns, setUseColumns] = useState<OptionTypeBase>({
    "work": true,
    "person": true,
    "treatment": true,
    "business": true,
    "address": true,
    "other": true,
  })
  const [personMin, setPersonMin] = useState("")
  const [personMax, setPersonMax] = useState("")
  const [holidayMin, setHolidayMin] = useState("")
  const [holidayMax, setHolidayMax] = useState("")
  const [overtimeMin, setOvertimeMin] = useState("")
  const [overtimeMax, setOvertimeMax] = useState("")
  const [incomeMin, setIncomeMin] = useState("")
  const [incomeMax, setIncomeMax] = useState("")
  const [incomeMonthMin, setIncomeMonthMin] = useState("")
  const [incomeMonthMax, setIncomeMonthMax] = useState("")
  const [prefecture, setPrefecture] = useState<OptionTypeBase[]>([])
  const [nearestStation, setNearestStation] = useState("")
  const [submitDisable, setSubmitDisable] = useState(false)

  const {
    work,
    person,
    treatment,
    business,
    address,
    other
  } = useColumns

  const searchKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value)
  }

  const useColumnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseColumns({...useColumns, [event.target.name]: event.target.checked})
  }

  const prefectureChange = (value:OptionTypeBase, select:ActionMeta<OptionTypeBase>) => {
    switch (select.action) {
      case "remove-value":
        setPrefecture(prefecture.filter((option:OptionTypeBase) => {
          if (option.value === select.removedValue.value) return false
          return true
        }))
        break
      case "select-option":
        setPrefecture([...prefecture, select.option as OptionTypeBase])
        break
      case "clear":
        setPrefecture([])
        break
    }
  }

  useImperativeHandle(ref, () => ({
    makeFormData: () => {
      let formData: FormData = {
        searchKeyword:searchKeyword,
        useSimilar:useSimilar,
        useColumns:Object.keys(useColumns).filter((key: string) =>{
          return useColumns[key]
        }),
        personMin:personMin.replace(",", ""),
        personMax:personMax.replace(",", ""),
        holidayMin:holidayMin.replace(",", ""),
        holidayMax:holidayMax.replace(",", ""),
        overtimeMin:overtimeMin.replace(",", ""),
        overtimeMax:overtimeMax.replace(",", ""),
        incomeMin:incomeMin.replace(",", "") === "" ? "" : incomeMin.replace(",", "") + "0000",
        incomeMax:incomeMax.replace(",", "") === "" ? "" : incomeMax.replace(",", "") + "0000",
        incomeMonthMin:incomeMonthMin.replace(",", "") === "" ? "" : incomeMonthMin.replace(",", "") + "0000",
        incomeMonthMax:incomeMonthMax.replace(",", "") === "" ? "" : incomeMonthMax.replace(",", "") + "0000",
        prefecture:prefecture.map((p:OptionTypeBase) => {
          return p.value
        }),
        nearestStation:nearestStation.length === 0 ? [] : nearestStation.split(/\s+/).map((station: string)=>{
          if (station.length !== 0 && station.slice(-1) !== "駅") {
            return station + "駅"
          }
          return station
        })
      }
      return formData
    },
  }))

  return (
    <Box className={classes.inputs}>
      <form>
        <Paper elevation={3} className={classes.paper}>
          検索キーワード<br/>
          <input
            type="text"
            maxLength={50}
            value={searchKeyword}
            onChange={searchKeywordChange}
            className={classes.text}/><br/>
          類義語
          <Tooltip
            title={<h1 className={classes.tooltip}>検索キーワードに類義語(例:経理→経理部、会計)が存在する場合に類義語も含めて検索します</h1>}
            placement="right"
          >
            <HelpOutlineIcon color="action" fontSize="inherit" />
          </Tooltip>
          <RadioGroup
            row
            value={useSimilar}
            onChange={event => setUseSimilar(event.target.value)}>
            <FormControlLabel value="true" control={<Radio color="primary" />} label="含む" />
            <FormControlLabel value="false" control={<Radio color="primary" />} label="含まない" />
          </RadioGroup>
        </Paper>
        <Paper elevation={3} className={classes.paper}>
          検索箇所<br/>
          <Checkbox
            name="work"
            checked={work}
            onChange={useColumnChange}
            color="primary"
          />仕事内容
          <Checkbox
            name="person"
            checked={person}
            onChange={useColumnChange}
            color="primary"
          />求めている人
          <Checkbox
            name="treatment"
            checked={treatment}
            onChange={useColumnChange}
            color="primary"
          />待遇<br/>
          <Checkbox
            name="business"
            checked={business}
            onChange={useColumnChange}
            color="primary"
          />事業内容
          <Checkbox
            name="address"
            checked={address}
            onChange={useColumnChange}
            color="primary"
          />住所
          <Checkbox
            name="other"
            checked={other}
            onChange={useColumnChange}
            color="primary"
          />その他<br/>
        </Paper>
        <Paper elevation={3} className={classes.paper}>
          募集人数（単位：人）<br/>
          <input
            type="text"
            maxLength={10}
            value={personMin}
            onChange={event => setPersonMin(event.target.value)}
            className={classes.number} />
          ～
          <input
            type="text"
            maxLength={10}
            value={personMax}
            onChange={event => setPersonMax(event.target.value)}
            className={classes.number} /><br/>
          年間休日（単位：日）<br/>
          <input
            type="text"
            maxLength={10}
            value={holidayMin}
            onChange={event => setHolidayMin(event.target.value)}
            className={classes.number} />
          ～
          <input
            type="text"
            maxLength={10}
            value={holidayMax}
            onChange={event => setHolidayMax(event.target.value)}
            className={classes.number} /><br/>
          月の残業時間（単位：時間）<br/>
          <input
            type="text"
            maxLength={10}
            value={overtimeMin}
            onChange={event => setOvertimeMin(event.target.value)}
            className={classes.number} />
          ～
          <input
            type="text"
            maxLength={10}
            value={overtimeMax}
            onChange={event => setOvertimeMax(event.target.value)}
            className={classes.number} /><br/>
          年収（単位：万円）<br/>
          <input
            type="text"
            maxLength={10}
            value={incomeMin}
            onChange={event => setIncomeMin(event.target.value)}
            className={classes.number} />
          ～
          <input
            type="text"
            maxLength={10}
            value={incomeMax}
            onChange={event => setIncomeMax(event.target.value)}
            className={classes.number} /><br/>
          月給（単位：万円）<br/>
          <input
            type="text"
            maxLength={10}
            value={incomeMonthMin}
            onChange={event => setIncomeMonthMin(event.target.value)}
            className={classes.number} />
          ～
          <input
            type="text"
            maxLength={10}
            value={incomeMonthMax}
            onChange={event => setIncomeMonthMax(event.target.value)}
            className={classes.number} /><br/>
          拠点所在地（都道府県）<br/>
          <Select
            isMulti
            placeholder=""
            onChange={prefectureChange}
            value={prefecture}
            options={prefectures} />
          最寄り駅<br/>
          <input
            type="text"
            maxLength={20}
            value={nearestStation}
            onChange={event => setNearestStation(event.target.value)}
            className={classes.text}/>
        </Paper>
        <Box className={classes.buttons}>
          <Button
            disabled={submitDisable}
            variant="contained"
            type="submit"
            className={classes.button}
            onClick={(event) => {
              setSubmitDisable(true)
              setTimeout(() =>{
                setSubmitDisable(false)
              }, 1000)
              onSubmit(event, "submit")
            }}
          >
            検索
          </Button>
        </Box>
      </form>
      
    </Box>
  )
}

const Sidebar = forwardRef(SidebarBase)

export {Sidebar}
export type {FormData}