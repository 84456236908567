import React from "react"

import {useHistory} from "react-router-dom"


const Redirect: React.FC = () => {
  const history = useHistory()

  const accountCode: string = window.sessionStorage.getItem("accountCode") || ""
  const authorizationToken: string = window.sessionStorage.getItem("authorizationToken") || ""
  const refreshToken: string = window.sessionStorage.getItem("refreshToken") || ""
  const expirationTime: string = window.sessionStorage.getItem("expirationTime") || ""

  if (
    accountCode.length === 0 ||
    authorizationToken.length === 0 ||
    refreshToken.length === 0 ||
    expirationTime.length === 0
  ) {
    history.push("/login")
  } else {
    history.push("/app")
  }

  return (
    <div>リダイレクト</div>
  )
}

export {Redirect}
