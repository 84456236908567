import React from "react"
import ReactDOM from "react-dom"

import "./bootstrap.css"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import "./index.css"
import {Redirect} from "./Redirect"
import {App} from "./app/App"
import {Login} from "./login/Login"
import {ForgotPassword} from "./login/ForgotPassword"
import {ChangePassword} from "./login/ChangePassword"


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path="/app" component={App} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route exact path="/change_password" component={ChangePassword} />
        <Route component={Redirect} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
)