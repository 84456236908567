import React, {useState, useRef} from "react"

import {makeStyles, Theme} from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import axios from "axios"
import type {ResponseType} from "axios"
import fileDownload from "js-file-download"
import ReactLoading from "react-loading"
import {useHistory} from 'react-router-dom'

import {Sidebar} from "./Sidebar"
import type {FormData} from "./Sidebar"
import {ResultTable} from "./ResultTable"
import type {ResultRow} from "./ResultTable"
import {
  submitURL,
  downloadURL,
  authURL,
  maxResult,
  maxDownload,
  refreshMinutes
} from "../Const"
import {parcentEncode} from "../ParcentEncode"


const useStyles = makeStyles((theme: Theme) => ({
  appbar:{
    height:"4vh",
    padding:"0",
    backgroundColor:"#3f51b5",
    boxShadow:"0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)"
  },
  sidebar:{
    height:"96vh"
  },
  result_table:{
    height:"96vh",
    overflowY:"scroll",
    borderLeft:"solid 1px hsl(0, 0%, 80%)"
  },
  loading:{
    position:"fixed",
    left:"40%",
    top:"40%"
  },
  logout:{
    backgroundColor:"#5f91f5",
    color:"white",
    margin:"0.5vh 1vh 0.5vh 0",
    padding:"0 0.5vh 0 0.5vh",
    fontSize:"1.5vh",
    float:"right"
  },
  logo:{
    textAlign:"left",
    margin:"0.5vh 1vh 0.5vh 0",
    padding:"0 0.5vh 0 0.5vh",
    float:"left",
    fontSize:"2vh"
  }
}))


const App: React.FC = () => {
  const sidebarRef = useRef<{makeFormData:() => FormData}>(null)
  
  const history = useHistory()

  const classes = useStyles()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRequesting, setIsRequesting] = useState<boolean>(false)

  const [offset, setOffset] = useState<number>(0)
  const [numResult, setNumResult] = useState<number>(0)
  const [resultData, setResultData] = useState<ResultRow[]>([])
  const [currentResultForm, setCurrentResultForm] = useState<FormData>({
    searchKeyword:"",
    useSimilar:"",
    useColumns:[],
    personMin:"",
    personMax:"",
    holidayMin:"",
    holidayMax:"",
    overtimeMin:"",
    overtimeMax:"",
    incomeMin:"",
    incomeMax:"",
    incomeMonthMin:"",
    incomeMonthMax:"",
    prefecture:[],
    nearestStation:[]
  })

  const accountCode: string = window.sessionStorage.getItem("accountCode") || ""
  const [authorizationToken, setAuthorizationToken] = useState<string>(
    window.sessionStorage.getItem("authorizationToken") || ""
  )
  const [refreshToken, setResreshToken] = useState<string>(
    window.sessionStorage.getItem("refreshToken") || ""
  )
  const [expirationTime, setExpirationTime] = useState<string>(
    window.sessionStorage.getItem("expirationTime") || ""
  )

  if (
    accountCode.length === 0 ||
    authorizationToken.length === 0 ||
    refreshToken.length === 0 ||
    expirationTime.length === 0
  ) {
    history.push("/login")
  }

  const onLogout = () => {
    window.sessionStorage.removeItem("accountCode")
    window.sessionStorage.removeItem("authorizationToken")
    window.sessionStorage.removeItem("refreshToken")
    window.sessionStorage.removeItem("expirationTime")
    history.push("/login")
  }

  const makeTimestamp = () => {
    const d = new Date()
    const formatted = `
      ${d.getFullYear()}-
      ${(d.getMonth()+1).toString().padStart(2, '0')}-
      ${d.getDate().toString().padStart(2, '0')}_
      ${d.getHours().toString().padStart(2, '0')}-
      ${d.getMinutes().toString().padStart(2, '0')}-
      ${d.getSeconds().toString().padStart(2, '0')}
    `.replace(/\n|\r|\s/g, '')
    return formatted
  }

  const overExpirationTime = () => {
    if (new Date(expirationTime).getTime() - new Date().getTime() <= refreshMinutes * 1000 * 60) {
      return true
    }
    return false
  }

  const makeAuthData = () => {
    return {
      "refreshToken":refreshToken
    }
  }

  const makeAuthHeader = () => {
    return {
      headers:{
        "X-Application-Name":"recruit-db-front"
      }
    }
  }

  const makeSubmitHeader = (token: string) => {
    return {
      headers:{
        "Authorization":token
      }
    }
  }

  const makeDownloadHeader = (token: string) => {
    return {
      headers:{
        "Authorization":token
      },
      responseType:"arraybuffer" as ResponseType
    }
  }

  const isNumber = (numStr: string) => {
    if (numStr === ""){
      return true
    }
    return /^[0-9]*$/.test(numStr)
  }

  const validateFormData = (formData: FormData) => {
    let validateErrors: string[] = []
    if (formData.searchKeyword.split(/\s+/).join("").length === 0){
      validateErrors.push("検索キーワードを入力してください")
    }
    if (formData.useColumns.length === 0){
      validateErrors.push("少なくとも1つの検索個所の入力が必要です")
    }
    if (!isNumber(formData.personMin) || !isNumber(formData.personMax)){
      validateErrors.push("募集人数は数値を入力してください")
    }
    if (!isNumber(formData.holidayMin) || !isNumber(formData.holidayMax)){
      validateErrors.push("年間休日は数値を入力してください")
    }
    if (!isNumber(formData.overtimeMin) || !isNumber(formData.overtimeMax)){
      validateErrors.push("月の残業時間は数値を入力してください")
    }
    if (!isNumber(formData.incomeMin) || !isNumber(formData.incomeMax)){
      validateErrors.push("年収は数値を入力してください")
    }
    if (!isNumber(formData.incomeMonthMin) || !isNumber(formData.incomeMonthMax)){
      validateErrors.push("月給は数値を入力してください")
    }
    return validateErrors
  }

  const submit = async (event: React.MouseEvent<HTMLButtonElement>, buttonType: string) => {
    event.preventDefault()
    if (isRequesting) {
      return
    }
    if (sidebarRef.current != null){
      let postOffset = 0
      let formData: FormData = sidebarRef.current.makeFormData()
      if (buttonType === "previous") {
        formData = currentResultForm
        postOffset = offset - maxResult
      } else if (buttonType === "next") {
        formData = currentResultForm
        postOffset = offset + maxResult
      }
      setOffset(postOffset)
      formData.offset = postOffset
      let validateErrors: string[] = validateFormData(formData)
      if (validateErrors.length > 0){
        alert(validateErrors.join("\n"))
        return
      }
      setIsRequesting(true)
      setIsLoading(true)
      let token: string = authorizationToken
      if (overExpirationTime()) {
        const encoded:string = parcentEncode(accountCode)
        const refreshResult: any = await axios.post(
          `${authURL}/account/${encoded}/token/refresh`,
          makeAuthData(),
          makeAuthHeader()
        )
          .catch(() => {
            alert("認証エラー発生")
            setIsLoading(false)
            setIsRequesting(false)
            return
          })
        window.sessionStorage.setItem("authorizationToken", refreshResult.data.idToken)
        window.sessionStorage.setItem("refreshToken", refreshResult.data.refreshToken)
        window.sessionStorage.setItem("expirationTime", refreshResult.data.expirationTime)
        setExpirationTime(refreshResult.data.expirationTime)
        setAuthorizationToken(refreshResult.data.idToken)
        setResreshToken(refreshResult.data.refreshToken)
        token = refreshResult.data.idToken
      }
      axios.post(submitURL, formData, makeSubmitHeader(token))
        .then((result) => {
          setResultData(result.data.resultData)
          setNumResult(result.data.numResult)
          setCurrentResultForm(formData)
        })
        .catch((error) => {
          if (error.response.status === 401){
            alert("認証エラー発生")
            return
          }
          alert("APIエラー発生")
        })
        .finally(() => {
          setIsLoading(false)
          setIsRequesting(false)
        })
    }
  }

  const download = async (event: React.MouseEvent<HTMLButtonElement>, downloadOffset: number) => {
    event.preventDefault()
    if (isRequesting) {
      return
    }
    if (sidebarRef.current != null){
      let formData: FormData = currentResultForm
      formData.offset = downloadOffset
      let validateErrors: string[] = validateFormData(formData)
      if (validateErrors.length > 0){
        alert(validateErrors.join("\n"))
        return
      }
      setIsRequesting(true)
      setIsLoading(true)
      let token: string = authorizationToken
      if (overExpirationTime()) {
        const encoded:string = parcentEncode(accountCode)
        const refreshResult: any = await axios.post(
          `${authURL}/account/${encoded}/token/refresh`,
          makeAuthData(),
          makeAuthHeader()
        )
          .catch(() => {
            alert("認証エラー発生")
            setIsLoading(false)
            setIsRequesting(false)
            return
          })
        window.sessionStorage.setItem("authorizationToken", refreshResult.data.idToken)
        window.sessionStorage.setItem("refreshToken", refreshResult.data.refreshToken)
        window.sessionStorage.setItem("expirationTime", refreshResult.data.expirationTime)
        setExpirationTime(refreshResult.data.expirationTime)
        setAuthorizationToken(refreshResult.data.idToken)
        setResreshToken(refreshResult.data.refreshToken)
        token = refreshResult.data.idToken
      }
      axios.post(downloadURL, formData, makeDownloadHeader(token))
        .then((result) => {
          const blob = new Blob([result.data], {
            type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          })
          let filename = `求人検索結果_${makeTimestamp()}.xlsx`
          if (result.status === 206 || downloadOffset > 0) {
            filename = `求人検索結果_${makeTimestamp()}_${downloadOffset}.xlsx`
          }
          fileDownload(blob, filename)
          if (result.status === 206) {
            setIsRequesting(false)
            const continueDownload = window.confirm(`${downloadOffset+1}～${downloadOffset+maxDownload}件目をダウンロードしました。一度にダウンロードできる件数は${maxDownload}件です。次の${maxDownload}件をダウンロードしますか？`)
            if (continueDownload) {
              download(event, downloadOffset + maxDownload)
            } else {
              setIsLoading(false)
            }
          } else {
            setIsLoading(false)
          }
        })
        .catch((error) => {
          if (error.response.status === 401){
            alert("認証エラー発生")
            setIsLoading(false)
            return
          }
          alert("APIエラー発生")
          setIsLoading(false)
        })
        .finally(() => {
          setIsRequesting(false)
        })
    }
  }

  const loading = isLoading ? (
    <ReactLoading
      color="#3f51b5"
      type="spin"
      width="20%"
      height="20%"
      className={classes.loading}/>
  ) : ""

  return (
    <Box>
      <AppBar className={classes.appbar} position="static">
        <Box>
          <Box className={classes.logo}>
            求人DB検索（アルファ版）
          </Box>
          <Button
            className={classes.logout}
            onClick={onLogout}
          >
            ログアウト
          </Button>
        </Box>
      </AppBar>
      <Grid container>
        <Grid item md={3} className={classes.sidebar}>
          <Sidebar
            ref={sidebarRef}
            onSubmit={submit}
            />
        </Grid>
        <Grid item md={9} className={classes.result_table}>
          <ResultTable
            offset={offset}
            numResult={numResult}
            onDownload={download}
            onSubmit={submit}
            resultData={resultData}/>
        </Grid>
      </Grid>
      {loading}
    </Box>
  )
}

export {App}
