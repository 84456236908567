const parcentEncode = (str: string) => {
  let encoded = str
    .replace("%", "%25")
    .replace(":", "%3A")
    .replace("/", "%2F")
    .replace("?", "%3F")
    .replace("#", "%23")
    .replace("[", "%5B")
    .replace("]", "%5D")
    .replace("@", "%40")
    .replace("!", "%21")
    .replace("$", "%24")
    .replace("&", "%26")
    .replace(",", "%27")
    .replace("(", "%28")
    .replace(")", "%29")
    .replace("*", "%2A")
    .replace("+", "%2B")
    .replace(",", "%2C")
    .replace(";", "%3B")
    .replace("=", "%3D")
    .replace(" ", "%20")

  return encoded
}

export {parcentEncode}