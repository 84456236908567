import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import React, { createRef, useCallback, useState } from "react"
import { maxResult } from "../Const"



const useStyles = makeStyles((theme: Theme) => ({
  result:{
    padding:"20px 10px 70px 10px"
  },
  scroll:{
    position:"fixed",
    bottom:"0",
    right:"0",
    margin:"0px 30px 10px 0px"
  },
  button:{
    marginLeft:"10px",
    backgroundColor:"#3f51b5",
    color:"white"
  },
  numResult:{
    margin:"10px 0 0 10px",
    fontSize:"1.75rem",
    '&:disabled': {
      backgroundColor: "#3f51b5",
      color:"white",
      boxShadow:""
    }
  },
  numPosition:{
    marginLeft:"10px",
    '&:disabled': {
      backgroundColor: "#3f51b5",
      color:"white",
      boxShadow:""
    }
  }
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#3f51b5",
      color: "white",
      padding:"6px",
      fontSize:"1rem",
      whiteSpace: "normal",
      wordBreak: "break-word"
    },
    body: {
      fontSize:"0.85rem",
      padding:"6px",
      whiteSpace: "normal",
      wordBreak: "break-word"
    }
  })
)(TableCell)

export type ResultRow = {
  companyName:string,
  lbcCode:string,
  industry:string,
  tel:string,
  address:string,
  crawledAt:string
}

interface Props {
  offset: number,
  numResult: number,
  onDownload: (event: React.MouseEvent<HTMLButtonElement>, downloadOffset: number) => void,
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>, buttonType: string) => void
  resultData: ResultRow[]
}

const ResultTable: React.FC<Props> = (props) => {
  const classes = useStyles()

  const {
    offset,
    numResult,
    onDownload,
    onSubmit,
    resultData
  } = props

  const scrollRef = createRef<HTMLDivElement>()
  const scrollTop = useCallback(() => {
    scrollRef!.current!.scrollIntoView({
      behavior: "smooth",
      block: 'start',
    })
  }, [scrollRef])

  const [previousDisable, setPreviousDisable] = useState(false)
  const [downloadDisable, setDownloadDisable] = useState(false)
  const [nextDisable, setNextDisable] = useState(false)

  let testRows = resultData.map((row, index) => {
    return (
      <TableRow key={index}>
      <StyledTableCell>{row.lbcCode}</StyledTableCell>
        <StyledTableCell>{row.companyName}</StyledTableCell>
        <StyledTableCell>{row.industry}</StyledTableCell>
        <StyledTableCell>{row.tel}</StyledTableCell>
        <StyledTableCell>{row.address}</StyledTableCell>
        <StyledTableCell>{row.crawledAt}</StyledTableCell>
      </TableRow>
    )
  })

  return (
    <>
      <div id="table_top" ref={scrollRef} />
      <Button
        disabled
        className={classes.numResult}>
          {"総件数：" + numResult}
      </Button>
      <Box id="result_table" className={classes.result}>
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell style={{width:"10%"}}>本社LBCコード</StyledTableCell>
                <StyledTableCell style={{width:"25%"}}>会社名</StyledTableCell>
                <StyledTableCell style={{width:"10%"}}>業種</StyledTableCell>
                <StyledTableCell style={{width:"10%"}}>TEL</StyledTableCell>
                <StyledTableCell style={{width:"35%"}}>本社住所</StyledTableCell>
                <StyledTableCell style={{width:"10%"}}>情報取得日</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testRows}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.scroll}>
          <Button
            variant="contained"
            disabled={resultData.length === 0 || downloadDisable}
            className={classes.button}
            onClick={(event) => {
              setPreviousDisable(true)
              setNextDisable(true)
              setDownloadDisable(true)
              setTimeout(() =>{
                setPreviousDisable(false)
                setNextDisable(false)
                setDownloadDisable(false)
              }, 1000)
              onDownload(event, 0)
            }}
          >
            ダウンロード
          </Button>
          <Button
            variant="contained"
            disabled={offset === 0 || previousDisable}
            className={classes.button}
            style={{paddingLeft:"20px"}}
            onClick={(event) => {
              setPreviousDisable(true)
              setNextDisable(true)
              setDownloadDisable(true)
              setTimeout(() =>{
                setPreviousDisable(false)
                setNextDisable(false)
                setDownloadDisable(false)
              }, 1000)
              onSubmit(event, "previous")
            }}
          >
            <ArrowBackIosIcon />
          </Button>
          <Button
            disabled
            className={classes.numPosition}>
              {numResult === 0 ? 0 : (offset + 1) + " ～ " + Math.min(offset + maxResult, numResult) + " / " + numResult}
          </Button>
          <Button
            variant="contained"
            disabled={offset + maxResult >= numResult || nextDisable}
            className={classes.button}
            onClick={(event) => {
              setPreviousDisable(true)
              setNextDisable(true)
              setDownloadDisable(true)
              setTimeout(() =>{
                setPreviousDisable(false)
                setNextDisable(false)
                setDownloadDisable(false)
              }, 1000)
              onSubmit(event, "next")
            }}
          >
            <ArrowForwardIosIcon />
          </Button>
          <Button
            variant="contained"
            disabled={numResult === 0}
            className={classes.button}
            onClick={scrollTop}><ArrowUpwardIcon/></Button>
        </Box>
      </Box>
    </>
  )
}

export { ResultTable }
