import React, {useState} from "react"

import {makeStyles, Theme} from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import ReactLoading from "react-loading"
import axios from "axios"
import {useHistory} from 'react-router-dom'

import {
  authURL
} from "../Const"
import {parcentEncode} from "../ParcentEncode"

const useStyles = makeStyles((theme: Theme) => ({
  back:{
    height:"100vh",
    width:"100%",
    backgroundColor:"#3f51b5",
    position:"relative"
  },
  centering:{
    position:"absolute",
    top:"50%",
    marginLeft:"50%",
    transform:"translateX(-50%) translateY(-50%)",
    textAlign:"center",
    color:"white",
    fontSize:"3rem"
  },
  formPaper:{
    padding:"20px",
    fontSize:"1.25rem"
  },
  input:{
    width:"50vh",
    borderRadius:"4px",
    minHeight:"38px",
    border:"solid 1px hsl(0, 0%, 80%)",
    marginBottom:"10px"
  },
  button:{
    backgroundColor:"#3f51b5",
    color:"white",
    textAlign:"center",
    fontSize:"1.25rem"
  },
  validate:{
    fontSize:"0.75rem",
    color:"red"
  },
  loading:{
    position:"fixed",
    left:"40%",
    top:"40%"
  }
}))

const ChangePassword: React.FC = () => {
  const history = useHistory()

  const classes = useStyles()

  const [accountCode, setAccountCode] = useState<string>("")
  const [activationCode, setActivationCode] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [buttonDisable, setButtonDisable] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const validate = () => {
    if (accountCode.length === 0) {
      return "ログインIDを入力してください"
    }
    if (activationCode.length === 0) {
      return "認証コードを入力してください"
    }
    if (newPassword.length === 0) {
      return "パスワードを入力してください"
    }
    if (newPassword !== confirmPassword) {
      return "確認用パスワードが違います"
    }
    return ""
  }

  const makeFormData = () => {
    return {
      "activationCode":activationCode,
      "newPassword":newPassword
    }
  }

  const makeAPIHeader = () => {
    return {
      headers:{
        "X-Application-Name":"recruit-db-front",
      }
    }
  }

  const changePassword = (event:React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setButtonDisable(true)
    setTimeout(() => {
      setButtonDisable(false)
    }, 1000)
    setIsLoading(true)
    axios.patch(
      `${authURL}/account/${parcentEncode(accountCode)}/password/confirm`, 
      makeFormData(), 
      makeAPIHeader()
    )
      .then((result) => {
        alert("パスワードの変更に成功しました。")
        history.push("/login")
      })
      .catch((error) => {
        alert("パスワードの変更に失敗しました。")
        setAccountCode("")
        setActivationCode("")
        setNewPassword("")
        setConfirmPassword("")
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const validateResult = validate()

  const loading = isLoading ? (
    <ReactLoading
      color="#3f51b5"
      type="spin"
      width="20%"
      height="20%"
      className={classes.loading}/>
  ) : ""

  return (
    <Box className={classes.back}>
      <Box className={classes.centering}>
        パスワード再設定
        <Paper elevation={3} className={classes.formPaper}>
          <form onSubmit={changePassword}>
            <input
              type="text"
              maxLength={255}
              className={classes.input}
              value={accountCode}
              placeholder="ログインID"
              onChange={event => setAccountCode(event.target.value)}
            /><br/>
            <input
              type="text"
              maxLength={32}
              className={classes.input}
              value={activationCode}
              placeholder="認証コード"
              onChange={event => setActivationCode(event.target.value)}
            /><br/>
            <input
              type="password"
              maxLength={256}
              className={classes.input}
              value={newPassword}
              placeholder="新しいパスワード"
              onChange={event => setNewPassword(event.target.value)}
            /><br/>
            <input
              type="password"
              maxLength={256}
              className={classes.input}
              value={confirmPassword}
              placeholder="パスワードの確認"
              onChange={event => setConfirmPassword(event.target.value)}
            /><br/>
            <Box className={classes.validate}>
              {validateResult}
            </Box>
            <Button
              type="submit"
              disabled={validateResult.length > 0 || buttonDisable}
              variant="contained"
              className={classes.button}
            >
              パスワードの変更
            </Button>
          </form>
        </Paper>
      </Box>
      {loading}
    </Box>
  )
}

export {ChangePassword}
