import React, {useState} from "react"

import {makeStyles, Theme} from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import ReactLoading from "react-loading"
import axios from "axios"
import {useHistory} from "react-router-dom"

import {
  authURL,
  confirmSubscriptionURL
} from "../Const"

const useStyles = makeStyles((theme: Theme) => ({
  back:{
    height:"100vh",
    width:"100%",
    backgroundColor:"#3f51b5",
    position:"relative"
  },
  version:{
    textAlign:"right",
    color:"white",
    marginRight:"10px"
  },
  centering:{
    position:"absolute",
    top:"50%",
    marginLeft:"50%",
    transform:"translateX(-50%) translateY(-50%)",
    textAlign:"center",
    color:"white",
    fontSize:"3rem"
  },
  formPaper:{
    padding:"20px",
    fontSize:"1.25rem"
  },
  input:{
    width:"50vh",
    borderRadius:"4px",
    minHeight:"38px",
    border:"solid 1px hsl(0, 0%, 80%)",
    marginTop:"10px"
  },
  button:{
    backgroundColor:"#3f51b5",
    color:"white",
    textAlign:"center",
    fontSize:"1.25rem"
  },
  validate:{
    fontSize:"0.75rem",
    color:"red"
  },
  forgot:{
    fontSize:"0.75rem",
    color:"grey",
    marginTop:"30px"
  },
  loading:{
    position:"fixed",
    left:"40%",
    top:"40%"
  },
  visibleButton:{
    fontSize:"0.75rem",
    color:"grey",
    margin:"0 0 20px 0",
    paddingTop:"0"
  }
}))

const Login: React.FC = () => {
  const histry = useHistory()

  const classes = useStyles()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [accountCode, setAccountCode] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [buttonDisable, setButtonDisable] = useState<boolean>(false)
  const [visiblePassword, setVisiblePassword] = useState<boolean>(false)

  const validate = () => {
    if (accountCode.length === 0) {
      return "ログインIDを入力してください"
    }
    if (password.length === 0) {
      return "パスワードを入力してください"
    }
    return ""
  }

  const makeFormData = () => {
    return {
      "accountCode":accountCode,
      "password":password,
    }
  }

  const makeAPIHeader = () => {
    return {
      headers:{
        "X-Application-Name":"recruit-db-front"
      }
    }
  }

  const login = (event:React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setButtonDisable(true)
    setTimeout(() => {
      setButtonDisable(false)
    }, 1000)
    setIsLoading(true)
    axios.post(`${authURL}/login`, makeFormData(), makeAPIHeader())
      .then((result) => {
        axios.post(confirmSubscriptionURL, {}, {headers:{"Authorization":result.data.idToken}})
          .then(() => {
            window.sessionStorage.setItem("expirationTime", result.data.expirationTime)
            window.sessionStorage.setItem("authorizationToken", result.data.idToken)
            window.sessionStorage.setItem("refreshToken", result.data.refreshToken)
            window.sessionStorage.setItem("accountCode", accountCode)
            histry.push("/")
          })
          .catch((error) => {
            throw error
          })
      })
      .catch((error) => {
        alert("認証に失敗しました。ログインIDとパスワードをご確認ください。")
        setAccountCode("")
        setPassword("")
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const loading = isLoading ? (
    <ReactLoading
      color="#3f51b5"
      type="spin"
      width="20%"
      height="20%"
      className={classes.loading}/>
  ) : ""

  const validateResult = validate()

  return (
    <Box className={classes.back}>
      <Box className={classes.version}>Ver.0.1.0</Box>
      <Box className={classes.centering}>
        求人検索（アルファ版）
        <Paper elevation={3} className={classes.formPaper}>
          <form onSubmit={login}>
            <Box className={classes.validate}>
              {validateResult}
            </Box>
            <input
              type="text"
              maxLength={255}
              className={classes.input}
              value={accountCode}
              placeholder="ログインID"
              onChange={event => setAccountCode(event.target.value)}
            /><br/>
            <input
              type={visiblePassword ? "text" : "password"}
              maxLength={256}
              className={classes.input}
              value={password}
              placeholder="パスワード"
              onChange={event => setPassword(event.target.value)}
            /><br/>
            <Button
              className={classes.visibleButton}
              onClick={()=>{setVisiblePassword(!visiblePassword)}}
            >
              パスワード表示切り替え
            </Button>
            <br/>
            <Button
              type="submit"
              disabled={validateResult.length > 0 || buttonDisable}
              variant="contained"
              className={classes.button}
            >
                ログイン
            </Button>
          </form>
          <Button className={classes.forgot} onClick={()=>{histry.push("/forgot_password")}}>パスワードを忘れた方はこちらをクリック</Button>
        </Paper>
      </Box>
      {loading}
    </Box>
  )
}

export {Login}
